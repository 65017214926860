import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import UserTypeField from "../../../Field/User/UserTypeField";
import UserActiveStatusField from "../../../Field/User/UserActiveStatusField";
import PhoneField from "../../../Field/User/PhoneField";
import UserSubscriptionStatusField from "../../../Field/User/UserSubscriptionStatusField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import RelatedUserField from "../../../Field/User/RelatedUserField";

export const FeedbackList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props} sort={{ field: 'createdAt', order: 'DESC' }} create={false}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow} />
                <CreatedAtField cellClassName={classes.narrow} />
                <ReferenceField source="user" reference="users">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="subject" cellClassName={classes.ellipsis200} />
                <TextField source="message" cellClassName={classes.ellipsis200} />
                <TextField source="device" cellClassName={classes.narrow} />
                <TextField source="version" cellClassName={classes.narrow} />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};
